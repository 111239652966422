<template>
  <div>
    <el-card v-loading="loadingFlag">
      <div class="vg_button_group">
        <div v-if="isShow">
          <el-button size="small" type="success" @click="editor">编辑</el-button>
        </div>
        <div v-else>
          <el-button type="primary" size="small" @click="submit">保存</el-button>
          <el-button size="small" @click="cancel">取消</el-button>
        </div>
      </div>
      <el-form size="mini" class="vg_partition" :disabled="isShow" :model="ProcForm">
        <el-row type="flex" justify="space-around" class="vd_rang vg_partition">
          <el-col :md="4">
            <el-form-item label="员工账号" prop="user_name">
              <el-input v-model="ProcForm.user_name" :disabled="true" placeholder="请选择对应员工">
                <template slot="append">
                  <el-link type="primary" disabled @click="openDialog()">选择</el-link>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="员工姓名" prop="stff_name">
              <el-input placeholder="员工姓名" v-model="ProcForm.stff_name" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="员工编号" prop="dept_name">
              <el-input placeholder="员工编号" v-model="ProcForm.stff_no" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="小组" prop="dept_team_name">
              <el-input placeholder="员工所在小组" v-model="ProcForm.dept_team_name" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="默认工序" prop="dept_team_name">
              <el-select v-model="proc_defa" placeholder="请选择">
                <el-option v-for="item in proc_listc" :key="item.key" :label="item.label" :value="item.key"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :md="4" align="center"> 工序账号分配<span class="vg_deep_red">(必选)</span>： </el-col>
          <el-col>
            <div style="text-align: center">
              <el-transfer
                style="text-align: left; display: inline-block"
                v-model="proc_listk"
                :titles="['工序总表', '工序赋表']"
                :button-texts="['去掉工序', '给予工序']"
                :data="procList"
                @change="tranChange"
              >
              </el-transfer>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <el-dialog :visible.sync="dialogVisible" width="50%" title="账号信息列表">
        <template>
          <span class="vd_tips">提示：(双击表单对应数据即可选中人员)</span>
          <br />
          <el-table :data="selTableData" border style="width: 100%" @row-dblclick="dbClickJp">
            <el-table-column prop="user_name" label="员工账号"> </el-table-column>
            <el-table-column prop="stff_name" label="员工姓名"> </el-table-column>
            <el-table-column prop="user_id" label="员工编号"> </el-table-column>
            <el-table-column prop="dept_team_name" label="小组"> </el-table-column>
          </el-table>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { userAPI } from '@api/modules/user';
import { procAPI } from '@api/modules/proc';
export default {
  data() {
    return {
      ProcForm: {},
      selTableData: [],
      dialogVisible: false,
      procList: [
        { key: 'manu', label: '手工' },
        { key: 'insp', label: '检验' },
        { key: 'mase', label: '机缝' },
        { key: 'embr', label: '电绣' },
        { key: 'stam', label: '印花' },
        { key: 'lase', label: '激光' },
        { key: 'comp', label: '复合' },
        { key: 'open', label: '开版' },
        { key: 'stpr', label: '备料' },
        { key: 'emcd', label: '制卡' }
      ],
      proc_defa: '',
      proc_listk: [],
      proc_listc: [],
      isShow: true,
      loadingFlag: true
    };
  },
  methods: {
    getUserlist() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.selTableData = res.data.data;
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    getProcForm() {
      get(procAPI.get_proc_by_id, { proc_id: this.$route.query.id })
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.ProcForm = res.data.data.form;
          this.proc_listk = this.ProcForm.proc_codes.split(',');
          this.proc_defa = res.data.data.form.proc_defa;
          for (let i in this.procList) {
            for (let j in this.proc_listk) {
              if (this.procList[i].key == this.proc_listk[j]) {
                this.proc_listc.push(this.procList[i]);
              }
            }
          }
          setTimeout(() => {
            this.loadingFlag = false;
          }, 500);
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    submit() {
      if (this.proc_listk.length == 0) {
        return this.$message.warning('最少选择一个工序');
      }
      if (this.proc_defa == '') {
        return this.$message.warning('请选择一个默认工序');
      }

      this.$confirm('此操作将保存工序分配, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          const ProcForm = {};
          ProcForm.proc_codes = this.proc_listk.join(',');
          ProcForm.proc_id = this.$route.query.id;
          ProcForm.proc_defa = this.proc_defa;
          post(procAPI.edit_proc, ProcForm)
            .then(res => {
              if (res.data.code !== 0) {
                return this.$message.error(res.data.msg);
              }
              this.isShow = true;
              this.$message.success(res.data.msg);
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(res => {
          this.$message.info('已取消保存');
        });
    },
    dbClickJp(row) {
      this.ProcForm = row;

      this.dialogVisible = false;
    },
    openDialog() {
      this.dialogVisible = true;
    },
    cancel() {
      this.$confirm('是否取消修改?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.jump('/proc_list');
        })
        .catch(() => {});
    },
    editor() {
      this.isShow = false;
    },
    tranChange(val) {
      this.proc_listc = [];
      for (let i in this.procList) {
        for (let j in val) {
          if (this.procList[i].key == val[j]) {
            this.proc_listc.push(this.procList[i]);
          }
        }
      }
      if (val.indexOf(this.proc_defa) == -1) {
        this.proc_defa = '';
      }
      if (val.length != 0) {
        this.proc_defa = val[0];
      }
    }
  },

  created() {
    this.getProcForm();
    this.getUserlist();
  }
};
</script>

<style lang="scss" scoped></style>
